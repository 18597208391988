<p-card *ngIf="responsiblesLoaded">
  <div class="p-grid top-header spacing-bottom">
    <div class="p-col-6 title">
      <p-header>{{'ADMINISTRATION' | translate}}</p-header>
    </div>
  </div>
  <form [formGroup]="orderForm" style="padding-top:20px">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12">
            <span class="p-float-label" pTooltip="{{ 'SITE' | translate }}*"
                  tooltipPosition="top">
                <textarea id="float-input" rows="5" cols="30" pInputTextarea (change)="onValueChange($event,'site')"
                          formControlName="site" [attr.disabled]="disableFields || isCanceled ? true : null"
                          [defaultValue]="order?.site" [autoResize]="true" class="textarea"></textarea>
              <label for="float-input" class="p-label">{{ 'SITE' | translate | uppercase }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="responsibles"
                              (OnValueChange)="updateResponsibleValue($event)"
                              [defaultValue]="order.responsible_id"
                              [disabled]="disableFields || isCanceled"
                              placeholder="SAMPLING.MANAGER">
        </app-prolabo-dropdown>
        <p [routerLink]="isUser ? '/user/profile' : '/admin/profile'" class="add-responsible-link">
          {{ '+ ' }}
          {{ 'ADD.SAMPLING.MANAGER' | translate }}
        </p>
      </div>
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="invoiceTypes"
                              (OnValueChange)="updateInvoiceTypes($event)"
                              [disabled]="disableFields || isCanceled"
                              [defaultValue]="order.monthly_invoice === 1 ? 1 : 2"
                              placeholder="INVOICE_TYPE"></app-prolabo-dropdown>
      </div>
      <div class="p-col-12" *ngIf="!isUser">
        <app-dropdown-select
          [options]="users"
          (OnValueChange)="updateUserId($event)"
          (OnFilter)="onFilter($event)"
          [defaultValue]="order.user_id"
          [offset]="limit"
          [disabled]="disableFields || isCanceled"
          placeholder="ORDER.OWNER"></app-dropdown-select>
      </div>
      <div class="p-col-12" *ngIf="currentUser.role_id !== 5 ">
        <app-prolabo-dropdown [options]="laboratories"
                              (OnValueChange)="updateLaboratoryId($event)"
                              [defaultValue]="order.laboratory_id"
                              [disabled]="disableFields || isCanceled"
                              placeholder="BRANCH">
        </app-prolabo-dropdown>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'remarks'"
                           [id]="'remarks'"
                           [keyword]="'REMARKS'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [defaultValue]="order.remarks"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'remarks')"
                           formControlName="remarks">
        </app-prolabo-input>
      </div>
    </div>
    <div class="p-grid top-header spacing-bottom">
      <div class="p-col-12 title">
        <p-header>{{'BILING_ADDRESS' | translate}}</p-header>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <app-prolabo-input [for]="'company'"
                           [id]="'company'"
                           [keyword]="'NAME'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'company')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.company"
                           formControlName="company">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'invoicing_address'"
                           [id]="'invoicing_address'"
                           [keyword]="'STREET'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'invoicing_address')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.address"
                           formControlName="invoicing_address">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'postal_code'"
                           [id]="'postal_code'"
                           [keyword]="'POSTCODE'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'postal_code')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.postal_code"
                           formControlName="postal_code">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'city'"
                           [id]="'city'"
                           [keyword]="'CITY'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'city')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.city"
                           formControlName="city">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'department'"
                           [id]="'department'"
                           [keyword]="'DEPARTMENT'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'department')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.department"
                           formControlName="department">
        </app-prolabo-input>
      </div>
      <div class="p-col-12">
        <app-prolabo-input [for]="'contact_person'"
                           [id]="'contact_person'"
                           [keyword]="'CONTACT_PERSON'"
                           [type]="'text'"
                           [required]="false"
                           [inputType]="'basic'"
                           [disabled]="disableFields || isCanceled"
                           (OnValueChange)="onValueChange($event,'contact_person')"
                           [defaultValue]="order?.invoice_address?.length > 0 && order?.invoice_address[(order?.invoice_address?.length -1)]?.contact_person"
                           formControlName="contact_person">
        </app-prolabo-input>
      </div>
    </div>
  </form>
</p-card>

import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services';
import { AdminUsersService } from 'src/app/admin/services/admin-users.service';
import { Router } from '@angular/router';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { AdminStatics } from '../../../statics/admin-statics';
import { UserShort } from '../../../models/user';
import { FunctionsService } from '../../../services/functions.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: UserShort[];
  totalUsers;
  sortParams;
  searchParam;
  limit = 10;
  offset = 0;

  userHeaders = [
    {title: 'N°', sortLabel: AdminStatics.id},
    {title: 'NAME', sortLabel: AdminStatics.firstName},
    {title: 'SURNAME', sortLabel: AdminStatics.lastName},
    {title: 'INITIALS', sortLabel: AdminStatics.initials},
    {title: 'ROLE', sortLabel: AdminStatics.role},
    {title: 'STATUS', sortLabel: AdminStatics.isCompany},
    {title: 'COMPANY', sortLabel: AdminStatics.company},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];
  isAnalyst = false;

  constructor(private adminService: AdminUsersService,
              public resources: ResourcesService,
              private router: Router,
              private functions: FunctionsService,
              private toast: ToastService) {
  }

  ngOnInit(): void {
    this.getUsers();
    this.isAnalyst = window.location.pathname.includes('analyst');
  }

  getUsers() {
    const params = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params[AdminStatics.orderBy] = this.sortParams;
    }

    if (this.searchParam) {
      params[AdminStatics.search] = this.searchParam;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.adminService.getUsers(params).subscribe(res => {
      this.users = res.users;
      this.totalUsers = res.total;
    });
  }

  deleteUser(userId) {
    const params = {locale: this.resources.getLanguage().description, id: userId};
    this.adminService.deleteUser(params).subscribe(res => {
        this.router.navigateByUrl('/admin/users');
        this.toast.showBottomCenterInfo(res.message);
        this.searchParam = null;
        this.getUsers();
      },
      error => {
        this.functions.showError(error);
      });
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getUsers();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getUsers();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getUsers();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getUsers();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../../admin/models/user';
import { DropdownModel, RadioModel } from '../../../../shared/components/dropdown/dropdown.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResourcesService } from '../../../../shared/services/resources.service';
import { ToastService } from '../../../../shared/services';

@Component({
  selector: 'app-prolabo-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent implements OnInit, OnChanges {

  @Input() currentUser: User;
  @Input() validationClicked = false;

  languages: DropdownModel[] = [];
  titles: DropdownModel[] = [];
  profileForm: FormGroup;
  signaturePad: any;
  profileOriginalValue: any;
  company: RadioModel[] = [];

  type: FormControl;
  selectedType: any = null;
  isCompany: boolean;

  @Output() profileFormEvent = new EventEmitter<FormGroup>();
  @Output() profileOriginalFormEvent = new EventEmitter<any>();
  @Output() isCompanyEvent = new EventEmitter<boolean>();
  @Output() validateClicked = new EventEmitter<boolean>();


  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private resources: ResourcesService,
              private toast: ToastService) { }

  ngOnInit(): void {
    if (this.resources.getPlatformTitles()) {
      this.setTitles(this.resources.getPlatformTitles().titles);
    } else {
      this.setTitles(this.resources.backupTitles.titles);
    }
    if (this.resources.getPlatformLanguages()) {
      this.setLanguages(this.resources.getPlatformLanguages().languages);
    } else {
      this.setLanguages(this.resources.backupLanguages.languages);
    }
    this.setEnterprise();
    this.type = this.formBuilder.control('');
    this.profileForm = this.formBuilder.group({
      first_name: [this.currentUser.first_name, [Validators.required]],
      last_name: [this.currentUser.last_name, [Validators.required]],
      email: [this.currentUser.email, [Validators.required, Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      invoice_email: [this.currentUser.invoice_email, [Validators.email, Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$')]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
      company: [this.currentUser.company, [Validators.required]],
      title_id: [this.currentUser.title_id, [Validators.required]],
      address: [this.currentUser.address.address, [Validators.required]],
      postal_code: [this.currentUser.address.postal_code, [Validators.required]],
      city: [this.currentUser.address.city, [Validators.required]],
      phone: [this.currentUser.phone, [Validators.required]],
      mobile: [this.currentUser.mobile, [Validators.required]],
      lang_id: [this.currentUser.lang_id],
      is_company: [this.currentUser.is_company],
      locale: [this.resources.getLanguage().description]
    });
    this.profileOriginalValue = this.profileForm.value;
    this.profileOriginalFormEvent.emit(this.profileOriginalValue);
    if (this.currentUser.is_company === 0) {
      this.selectedType = this.company[0];
      this.isCompany = false;
      this.profileForm.patchValue({
        is_company: 0
      });
    } else if (this.currentUser.is_company === 1) {
      this.selectedType = this.company[1];
      this.isCompany = true;
      this.profileForm.patchValue({
        is_company: 1
      });
    }
  }

  updateLanguageValue(value) {
    this.profileForm.patchValue({
      lang_id: value
    });
  }

  updateTitleValue(value) {
    this.profileForm.patchValue({
      title_id: value
    });
  }

  private setLanguages(languages) {
    languages.forEach(lng => {
      const name = this.translate.instant(lng.language.toUpperCase());
      this.languages.push({id: lng.id, text: name, value: lng.language, description: lng.description});
    });
  }

  private setTitles(titles) {
    titles.forEach(title => {
      const name = this.translate.instant(title.name.toUpperCase());
      this.titles.push({id: title.id, text: name, value: title.name});
    });
  }

  private setEnterprise() {
    this.company.push({key: 0, name: this.translate.instant('PRIVATE')});
    this.company.push({key: 1, name: this.translate.instant('COMPANY')});
  }

  checkPasswords(value) {
    this.profileForm.patchValue({
      password_confirmation: value
    });
    if (this.profileForm.controls.password.value !== this.profileForm.controls.password_confirmation.value) {
      this.toast.showBottomCenterError(this.translate.instant('FORM.PASSWORD'));
    }
  }

  handleTypeChange($event: any) {
    if ($event.key === 0) {
      this.isCompany = false;
      this.profileForm.patchValue({
        is_company: 0
      });
    } else if ($event.key === 1) {
      this.isCompany = true;
      this.profileForm.patchValue({
        is_company: 1
      });
    }
  }

  validate() {
    this.profileFormEvent.emit(this.profileForm);
    this.validateClicked.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validationClicked) {
      setTimeout(() => {
        if (this.validationClicked) {
          if (this.isCompany) {
            this.profileFormEvent.emit(this.profileForm);
            this.validateClicked.emit(false);
          } else if (!this.isCompany) {
            this.profileForm.removeControl('company');
            this.profileFormEvent.emit(this.profileForm);
            this.validateClicked.emit(false);
          }
        }
      }, 0);
    }
  }
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownModel } from '../dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true
    }
  ]
})
export class DropdownSelectComponent implements OnInit, OnChanges {

  @Input() options: DropdownModel[];
  @Input() control: string;
  @Input() type: string;
  @Input() disabled = false;
  @Input() selectedOption: DropdownModel;
  @Input() placeholder: string;
  @Input() defaultValue: number;
  @Input() offset: number;
  @Input() inputId: any;
  @Input() icon: string;
  @Input() shouldAddIcon: string;

  @Output() OnValueChange = new EventEmitter<string>();
  @Output() OnFilter = new EventEmitter<any>();
  @Output() OnHideHappened = new EventEmitter<string>();
  @Output() deleteClickedEvent = new EventEmitter<any>();

  selectedOptionFormControl: FormControl;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption) {
      this.selectedOptionFormControl.setValue(this.selectedOption);
    }
    if (changes.disabled && this.selectedOptionFormControl) {
      if (changes.disabled.currentValue) {
        this.selectedOptionFormControl.disable();
      } else {
        this.selectedOptionFormControl.enable();
      }
    }
  }

  onChange(event) {
    this.OnValueChange.emit(event.value);
  }

  ngOnInit(): void {
    this.selectedOptionFormControl = new FormControl({value: this.defaultValue, disabled: this.disabled});
  }

  onFilter($event: any) {
    this.OnFilter.emit($event);
  }


  onHide($event: any) {
    this.OnHideHappened.emit($event);
  }

  deleteClicked($event) {
    this.deleteClickedEvent.emit($event);
  }
}

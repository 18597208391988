<p-card [style]="{'margin-bottom': '20px'}" *ngIf="order">
  <div class="p-grid top-header">
    <div class="p-col-10 title">
      <p-header>{{'RECEIPT' | translate}}</p-header>
    </div>
    <div class="p-col-2 align-icons" *ngIf="!isCanceled">
      <app-prolabo-icon class="icon-black" (click)="addEmail()" icon="add"></app-prolabo-icon>
    </div>
  </div>

  <ng-container *ngFor="let email of emails; let i=index">
    <div class="p-grid emails">
      <div class="p-col-10">
        <p-checkbox name="email" [inputId]="email.key" [disabled]="true" [value]="true"
                    [(ngModel)]="selectedEmail"></p-checkbox>
        <label
          class="radio-label"
          [for]="email.key"
          pTooltip="{{email.name}}"
          tooltipPosition="top">
          {{email.name}}
        </label>
      </div>
      <div class="p-col-2 align-icons" *ngIf="!isCanceled">
        <app-prolabo-icon class="icon-white" (click)="delete(email)" icon="close"></app-prolabo-icon>
      </div>
    </div>
  </ng-container>

  <div class="p-grid" *ngIf="addEmailClicked">
    <div class="p-col-8">
      <input type="text" [(ngModel)]="newMail"
             [placeholder]="'EMAIL' | translate" class="list-input" pInputText>
    </div>
    <div class="p-col-4 align-icons">
      <app-prolabo-icon class="icon-green" (click)="submit(newMail)" icon="check"></app-prolabo-icon>
      <app-prolabo-icon class="icon-white" (click)="cancel()" icon="close"></app-prolabo-icon>
    </div>
  </div>

  <div class="p-grid top-header spacing-bottom">
    <div class="p-col-12 title">
      <p-header>{{'LANGUAGE.REPORT' | translate}}</p-header>
    </div>
  </div>

  <div class="p-grid language-radio-container">
    <ng-container *ngFor="let language of languagesRadio">
      <div class="p-col-4 language-radio" *ngIf="language.name !== 'English'">
        <p-radioButton [inputId]="language.key" name="category" [(ngModel)]="selectedLanguage"
                       class="radio-icon"
                       (ngModelChange)="handleLanguageChange($event)"
                       [disabled]="isCanceled"
                       [value]="language"></p-radioButton>
        <p class="radio-label">{{language.name.toUpperCase() | translate}}</p>
      </div>
    </ng-container>
  </div>
</p-card>

import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ScoreCardField, ScoreCardFieldOption, ScoreCardFieldType} from '../../../../../models/score-card-model';
import {SampleScoreCard, ScoreCardValues} from '../../../../../models/simple-order-response';
import {DropdownModel} from '../../../../../../shared/components/dropdown/dropdown.model';
import {TranslateService} from '@ngx-translate/core';
import {ResourcesService} from '../../../../../../shared/services/resources.service';
import {AdminOrdersService} from '../../../../../services/admin-orders.service';
import {ToastService} from '../../../../../../shared/services';
import {AdminScorecardModelService} from '../../../../../services/admin-scorecard-model.service';
import {concat} from 'rxjs';
import {FunctionsService} from '../../../../../services/functions.service';
import {take} from 'rxjs/operators';
import {DropdownFieldComponent} from '../../../../../../shared/components/dropdown-field/dropdown-field.component';
import {InputFieldComponent} from '../../../../../../shared/components/input-field/input-field.component';
import {DialogService} from 'primeng/dynamicdialog';
import {FreeOptionComponent} from '../../free-option/free-option.component';
import {MultiSelect} from 'primeng/multiselect';

@Component({
  selector: 'app-prolabo-score-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ScoreListComponent implements OnInit, AfterViewInit {

  @Input() items: ScoreCardField[];
  @Input() samples: SampleScoreCard[];
  @Input() allDropdownOptions: DropdownModel[];
  @Input() finalValidate: boolean;
  public dropdownOptions: DropdownModel[];
  public magnificationsLoaded: boolean;
  @Output() counter: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedControlSamples: EventEmitter<number[]> = new EventEmitter<number[]>();
  temporaryRows: string[] = new Array(0);
  selectedItems: Record<string, number[]> = {};
  options: Record<string, DropdownModel[]> = {};
  idOptions: Record<string, number> = {};
  @ViewChildren(DropdownFieldComponent) dropdownFields!: QueryList<DropdownFieldComponent>;
  @ViewChildren(MultiSelect) multiSelectFields!: QueryList<MultiSelect>;
  @ViewChildren(InputFieldComponent) inputFields!: QueryList<InputFieldComponent>;
  finalValidateValues = new Set(new Array(0));
  private deleteClicked: boolean;
  controleFields = new Array(0);
  allChecked = false;

  constructor(private translate: TranslateService,
              private toast: ToastService,
              private ordersService: AdminOrdersService,
              private scorecard: AdminScorecardModelService,
              private functions: FunctionsService,
              private dialogService: DialogService,
              private cdr: ChangeDetectorRef,
              private resources: ResourcesService) {
  }

  ngAfterViewInit(): void {
    this.disableFieldsAfterViewInit();
  }

  ngOnInit() {
    if (this.finalValidate) {
      this.items.push({
        id: 1,
        created_at: null,
        deleted_at: null,
        deleted_by: null,
        scorecard_model_id: null,
        scorecard_field_type_id: 2,
        slug: 'Controle',
        field_name: 'Controle',
        updated_at: null,
        created_by: null,
        updated_by: null,
        required: 1,
        type: 'text',
        input_type: 'text',
        scorecard_field_type: {id: 2, slug: 'checkbox'},
        scorecard_field_option: [],
        unique_element: 0
      });
    }
    this.makeRecord();
    this.setSamples();
    this.swapFields();
    this.generateOptions();
  }

  generateOptions() {
    this.samples.forEach(sample => {
      sample.headers.forEach(field => {
        this.options[`${field.id}_${sample.sample_id}`] =
          this.setDropdownOptions(field.scorecard_field_option, this.samples[0].sample_type_id, field.slug);
        if (sample.sample_type_id === 2) {
          const valueResult = this.getFieldValue('result', 'dropdown', sample.id, 1);
          if (field.slug === 'asbestos_type' && valueResult === 18) {
            this.options[`${field.id}_${sample.sample_id}`] = this.options[`${field.id}_${sample.sample_id}`].filter(x => x.id !== 65);
          }
        }
        if (field.slug === 'material_type_A') {
          this.options[`${field.id}_${sample.sample_id}`] = this.options[`${field.id}_${sample.sample_id}`].sort((a, b) => {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
        }
        if (field.slug === 'material_type_A' ||
          field.slug === 'material_type_B' ||
          field.slug === 'other_fibers' ||
          field.slug === 'colors') {
          this.options[`${field.id}_${sample.sample_id}`]
            .push({value: -1, id: -1, text: this.translate.instant('FREE_OPTION_MODAL'), isDeletable: 0});
        }
      });
    });

  }

  disableFieldsAfterViewInit() {
    setTimeout(() => {
      this.samples.forEach(sample => {
        sample.headers.forEach(() => {
          const value = this.getFieldValue('structure_type', 'dropdown', sample.id, 1);
          if (value === 66) {
            this.disableFields(this.idOptions[`${sample.sample_number}-structure_type`], 1, sample.sample_type_id);
          }
          if (sample.sample_type_id === 8) {
            const value2 = this.getFieldValue('result', 'dropdown', sample.id, 1);
            if (value2 === 138) {
              this.disableFields(this.idOptions[`${sample.sample_number}-percentage`], 1, sample.sample_type_id);
            }
          }
          if (sample.sample_type_id === 2) {
            const value3 = this.getFieldValue('result', 'dropdown', sample.id, 1);
            if (value3 === 1 || value3 === 0) {
              this.disableFields(this.idOptions[`${sample.sample_number}-asbestos_type`], 1, sample.sample_type_id);
            }
          }
        });
        if (sample.number_of_rows > 0) {
          sample.headers.filter(x => x.slug !== 'microscope' && x.slug !== 'remarks').forEach(() => {
            for (let i = 0; i < sample.number_of_rows; i++) {
              const rowValue = this.getFieldValue('structure_type', 'dropdown', sample.id, i + 2);
              if (rowValue === 66) {
                this.disableFields(this.idOptions[`${sample.sample_number}-structure_type-${i + 2}`], i + 2, sample.sample_type_id);
              }
            }
          });
        }
      });
    }, 100);
  }

  makeRecord() {
    let count = 25;
    this.samples.forEach(x => {
      if (x.number_of_rows == null || x.number_of_rows === 0) {
        this.items.forEach(field => {
          if (field.slug === 'Controle') {
            this.controleFields.push(count);
          }
          this.idOptions[`${x.sample_number}-${field.slug}`] = count;
          count++;
        });
      }
      if (x.number_of_rows > 0) {
        this.items.forEach(field => {
          this.idOptions[`${x.sample_number}-${field.slug}`] = count;
          count++;
        });
        for (let i = 0; i < x.number_of_rows; i++) {
          // first rows
          this.items.filter(y => y.slug !== 'microscope' && y.slug !== 'remarks').forEach(field => {
            this.idOptions[`${x.sample_number}-${field.slug}-${i + 2}`] = count;
            count++;
          });
        }
      }
    });
  }

  setSamples() {
    this.samples.forEach((sample) => {
        sample.headers = this.items;
        this.selectedItems[`other_fibers_${sample.sample_id}`] = sample.scorecard_value.length > 0 ?
          sample.scorecard_value
            .filter(x => x.scorecard_field.slug === 'other_fibers').map(x => x.scorecard_field_option_id) : [];
        this.selectedItems[`colors_${sample.sample_id}`] = sample.scorecard_value.length > 0 ?
          sample.scorecard_value
            .filter(x => x.scorecard_field.slug === 'colors').map(x => x.scorecard_field_option_id) : [];
        this.selectedItems[`result_${sample.sample_id}`] = sample.scorecard_value.length > 0 ?
          sample.scorecard_value
            .filter(x => x.scorecard_field.slug === 'result').map(x => x.scorecard_field_option_id) : [];
      }
    );
  }

  swapFields() {
    if (this.samples[0].sample_type_id === 2) {
      [this.samples[0].headers[1], this.samples[0].headers[3]] = [this.samples[0].headers[3], this.samples[0].headers[1]];
    }
  }

  getDropdownTranslations(id: number, typeId: number): string {
    if (typeId === 2) {
      if (id === 1) {
        return 'ASBESTOS_NOT_DETECTED';
      } else if (id === 2) {
        return 'ASBESTOS_ACTINOLITE';
      } else if (id === 3) {
        return 'ASBESTOS_AMOSITE';
      } else if (id === 4) {
        return 'ASBESTOS_ANTHOPHYLLITE';
      } else if (id === 12) {
        return 'ASBESTOS_CHRYSOTILE';
      } else if (id === 13) {
        return 'ASBESTOS_CROCIDOLITE';
      } else if (id === 14) {
        return 'ASBESTOS_TREMOLITE';
      } else if (id === 18) {
        return 'ASBESTOS_DETECTED';
      }
    } else if (typeId === 3) {
      if (id === 5) {
        return 'LEAD_NOT_DETECTED';
      } else if (id === 6) {
        return 'LEAD_BELOW';
      } else if (id === 7) {
        return 'LEAD_UNDER';
      }
    } else if (typeId === 5) {
      if (id === 12) {
        return 'HAP_NOT_DETECTED';
      } else if (id === 13) {
        return 'HAP_DETECTED';
      }
    } else if (typeId === 8) {
      if (id === 138) {
        return this.translate.instant('ASBESTOS_NOT_DETECTED');
      } else if (id === 140) {
        return this.translate.instant('ASBESTOS_ACTINOLITE');
      } else if (id === 141) {
        return this.translate.instant('ASBESTOS_AMOSITE');
      } else if (id === 143) {
        return this.translate.instant('ASBESTOS_ANTHOPHYLLITE');
      } else if (id === 139) {
        return this.translate.instant('ASBESTOS_CHRYSOTILE');
      } else if (id === 142) {
        return this.translate.instant('ASBESTOS_CROCIDOLITE');
      } else if (id === 144) {
        return this.translate.instant('ASBESTOS_TREMOLITE');
      }
    }
    return null;
  }

  public setDropdownOptions(options, sampleType, fieldName): DropdownModel[] {
    const optionTypes = new Array(0);
    if (fieldName !== 'colors' &&
    fieldName !== 'other_fibers' &&
    this.samples[0].sample_type_id === 2 ? fieldName === 'result' || fieldName === 'asbestos_type' : fieldName !== 'result') {
      optionTypes.push({id: 0, text: '    ', value: 0});
    }

    options.forEach(type => {
      if (optionTypes.find(x => x.id === type.id) == null) {
        optionTypes.push(
          {
            id: type.id,
            text: this.getDropdownTranslations(type.id, sampleType) ? this.getDropdownTranslations(type.id, sampleType) :
              this.samples[0].sample_type_id === 8 ? this.translate.instant(type.value) : type.value,
            value: type.id,
            isDeletable: type.is_deletable
          }
        );
      }
    });

    return optionTypes;
  }

  public getMagnificationDropdownOptions(sampleId) {
    const body = {
      locale: this.resources.getLanguage().description,
      sample_id: sampleId
    };
    this.dropdownOptions = new Array(0);
    this.dropdownOptions.push({id: 0, text: '    ', value: 0});
    this.magnificationsLoaded = false;

    this.scorecard.getDropdownOptions(body).pipe(take(1)).subscribe(result => {
      this.magnificationsLoaded = true;
      this.setMagnificationDropdownOptions(result);
    });
  }

  setMagnificationDropdownOptions(result) {
    result.options.forEach(type => {
      this.dropdownOptions.push(
        {
          id: type.id,
          text: type.value,
          value: type.id
        }
      );
    });
  }

  disableFields(inputId: number, rowId: number, sampleTypeId: number) {
    if (sampleTypeId === 8) {
      const percentage = this.dropdownFields.find(x => x.inputId === inputId.toString());
      percentage?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
    }
    if (sampleTypeId === 2) {
      const sampleType = this.dropdownFields.find(x => x.inputId === inputId.toString());
      sampleType?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
    } else {
      if (rowId > 1) {
        const magnification = this.dropdownFields.find(e => e.inputId === (+inputId - 3));
        magnification?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
        // @ts-ignore
        const fieldNumber = this.inputFields.find(e => e.id === (+inputId - 2));
        fieldNumber?.withZero.disable({onlySelf: true, emitEvent: true});
        fieldNumber?.setDisabledState(true);
        const fiberType = this.dropdownFields.find(e => e.inputId === (+inputId - 1));
        fiberType?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
        const oneEighth = this.dropdownFields.find(e => e.inputId === (+inputId + 1));
        oneEighth?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
      } else {
        const magnification = this.dropdownFields.find(e => e.inputId === (+inputId - 3).toString());
        magnification?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
        const fieldNumber = this.inputFields.find(e => e.id === (+inputId - 2).toString());
        fieldNumber?.setDisabledState(true);
        fieldNumber?.withZero.disable({onlySelf: true, emitEvent: true});
        const fiberType = this.dropdownFields.find(e => e.inputId === (+inputId - 1).toString());
        fiberType?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
        const oneEighth = this.dropdownFields.find(e => e.inputId === (+inputId + 1).toString());
        oneEighth?.selectedOptionFormControl.disable({onlySelf: true, emitEvent: true});
      }
    }
  }

  updateDropdownOption($event, sampleId, scorecardId, scorecardFieldId, keyword, orderId, sampleTypeId, rowId, inputId, isMultiSelect, sampleID) {
    if (!this.deleteClicked) {
      if (($event === -1 && sampleTypeId !== 2) || ($event?.length > 0 && sampleTypeId !== 2 ? $event.includes(-1) : false)) {
        const ref = this.dialogService.open(FreeOptionComponent, {
          showHeader: false,
          width: '33%',
        });

        ref.onClose.subscribe(res => {
          if (res) {
            const body = {
              order_id: orderId,
              scorecard_field_id: scorecardFieldId,
              option_value: res,
              scorecard_model_id: scorecardId,
              sample_id: sampleId,
              locale: this.resources.getLanguage().description
            };
            const samplesBody = {
              order_id: orderId,
              sample_type_id: sampleTypeId,
              locale: this.resources.getLanguage().description
            };

            const getSamples = this.scorecard.getSamples(samplesBody);

            const setFreeOption = this.scorecard.setFreeOption(body);

            concat(setFreeOption, getSamples).subscribe(result => {
              if (result.option) {
                this.options[`${scorecardFieldId}_${sampleID}`].push({
                  id: result.option.id,
                  text: this.getDropdownTranslations(result.option.id, sampleTypeId) ?
                    this.getDropdownTranslations(result.option.id, sampleTypeId) :
                    this.samples[0].sample_type_id === 8 ? this.translate.instant(result.option.value) : result.option.value,
                  value: result.option.id,
                  isDeletable: 1
                });
                if (keyword === 'colors') {
                  this.samples.filter(x => x.id !== sampleId).forEach(sample => {
                    this.options[`${scorecardFieldId}_${sample.sample_id}`].push({
                      id: result.option.id,
                      text: this.getDropdownTranslations(result.option.id, sampleTypeId) ?
                        this.getDropdownTranslations(result.option.id, sampleTypeId) :
                        this.samples[0].sample_type_id === 8 ? this.translate.instant(result.option.value) : result.option.value,
                      value: result.option.id,
                      isDeletable: 1
                    });
                  });
                }
              }
              if (result.samples) {
                this.samples = result.samples;
                this.counter.emit(result.counter);
                this.setSamples();
                this.disableFieldsAfterViewInit();
              }
            }, error => {
              this.functions.showError(error);
            });
          }
        });
        return;
      }
      // @ts-ignore
      document.getElementById(inputId.toLocaleString('en'))?.autofocus = true;
      // @ts-ignore
      document.getElementById((inputId + 1).toLocaleString('en'))?.autofocus = true;
      if (isMultiSelect) {
        const values = this.selectedItems[`${keyword}_${sampleID}`];
        const difference = this.getDifferentElements($event, this.selectedItems[`${keyword}_${sampleID}`]);
        $event = difference[0];
        $event == null ? $event = values[0] : $event = $event;
      }
      if (sampleTypeId === 2 && keyword === 'result' && ($event === -1 || $event === 0)) {
        this.delete(keyword, sampleId, rowId, orderId, sampleTypeId, inputId);
      } else {
        if (keyword === 'structure_type' && $event === 66) {
          this.disableFields(inputId, rowId, sampleTypeId);
        }
        const samplesBody = {
          order_id: orderId,
          sample_type_id: sampleTypeId,
          locale: this.resources.getLanguage().description
        };
        const scoreCardValues = this.findValueBySlug(keyword, sampleId, rowId);

        if (scoreCardValues) {
          const body = {
            scorecard_model_id: scorecardId,
            scorecard_field_id: scorecardFieldId,
            sample_id: sampleId,
            locale: this.resources.getLanguage().description,
            scorecard_value_id: scoreCardValues.id,
            scorecard_field_option_id: $event
          };
          const update = this.ordersService.updateAnalyzedField(body);
          const getSamples = this.scorecard.getSamples(samplesBody);
          concat(update, getSamples).subscribe(result => {
            if (result.samples) {
              this.samples = result.samples;
              this.counter.emit(result.counter);
              this.setSamples();
              this.disableFieldsAfterViewInit();
              setTimeout(() => {
                document.getElementById((inputId + 1).toLocaleString('en'))?.focus();
              }, 0);
            }
          }, error => {
            this.functions.showError(error);
          });
        } else {
          const body = {
            scorecard_model_id: scorecardId,
            scorecard_field_id: scorecardFieldId,
            sample_id: sampleId,
            locale: this.resources.getLanguage().description,
            scorecard_field_option_id: $event
          };
          if (rowId > 1) {
            body[`row`] = rowId;
          }
          const create = this.ordersService.analyzeField(body);
          const getSamples = this.scorecard.getSamples(samplesBody);
          concat(create, getSamples).subscribe(result => {
            if (result.samples) {
              this.samples = result.samples;
              this.counter.emit(result.counter);
              this.setSamples();
              this.disableFieldsAfterViewInit();
            }
            setTimeout(() => {
              document.getElementById((inputId + 1).toLocaleString('en'))?.focus();
            }, 0);
          }, error => {
            this.functions.showError(error);
          });
        }
        if (sampleTypeId === 2 && keyword === 'result') {
          const valueResult = this.getFieldValue(keyword, 'dropdown', sampleId, 1);
          if (valueResult !== 18) {
            this.options[`107_${sampleID}`] = this.options[`107_${sampleID}`].filter(x => x.id !== 65);
          } else {
            this.options[`107_${sampleID}`] =
              this.setDropdownOptions(this.getScorecardFieldOption(107), this.samples[0].sample_type_id, 'asbestos_type');
          }
        }
      }
    }
  }

  private getScorecardFieldOption(scorecardFieldId: number) {
    return this.items.find(x => x.id === scorecardFieldId).scorecard_field_option;
  }

  inputChange($event: any, sampleId, scorecardId, scorecardFieldId, keyword, orderId, sampleTypeId, rowId, inputId, numInput = null) {
    document.getElementById(inputId.toLocaleString('en')).autofocus = true;
    if (sampleTypeId === 2 && keyword === 'remarks' && $event === '') {
      this.delete(keyword, sampleId, rowId, orderId, sampleTypeId, inputId);
    } else {
      const nextItem = document.getElementById((inputId + 1).toLocaleString('en'));
      if (nextItem) {
        nextItem.autofocus = true;
      }
      const samplesBody = {
        order_id: orderId,
        sample_type_id: sampleTypeId,
        locale: this.resources.getLanguage().description
      };
      const scoreCardValues = this.findValueBySlug(keyword, sampleId, rowId);
      if (scoreCardValues) {
        let body;

        if (numInput?.originalEvent instanceof MouseEvent) {
          body = {
            scorecard_model_id: scorecardId,
            scorecard_field_id: scorecardFieldId,
            sample_id: sampleId,
            locale: this.resources.getLanguage().description,
            scorecard_value_id: scoreCardValues.id,
            text_value: $event,
            sign: Math.sign($event) === 1 ? '+' : '-'
          };
        } else {
          body = {
            scorecard_model_id: scorecardId,
            scorecard_field_id: scorecardFieldId,
            sample_id: sampleId,
            locale: this.resources.getLanguage().description,
            scorecard_value_id: scoreCardValues.id,
            text_value: $event
          };
        }
        const update = this.ordersService.updateAnalyzedField(body);
        const getSamples = this.scorecard.getSamples(samplesBody);
        if (numInput) {
          setTimeout(() => {
            concat(update, getSamples).subscribe(result => {
              if (result.samples) {
                this.samples = result.samples;
                this.counter.emit(result.counter);
                this.setSamples();
                this.disableFieldsAfterViewInit();

                setTimeout(() => {
                  document.getElementById((inputId + 1).toLocaleString('en')).focus();
                }, 0);
              }
            }, error => {
              this.functions.showError(error);
            });
          }, 500);
        } else {
          concat(update, getSamples).subscribe(result => {
            if (result.samples) {
              this.samples = result.samples;
              this.counter.emit(result.counter);
              this.setSamples();
              this.disableFieldsAfterViewInit();

              setTimeout(() => {
                document.getElementById((inputId + 1).toLocaleString('en')).focus();
              }, 0);
            }
          }, error => {
            this.functions.showError(error);
          });
        }
      } else {
        const body = {
          scorecard_model_id: scorecardId,
          scorecard_field_id: scorecardFieldId,
          sample_id: sampleId,
          locale: this.resources.getLanguage().description,
          text_value: $event
        };
        if (rowId > 1) {
          body[`row`] = rowId;
        }
        const create = this.ordersService.analyzeField(body);
        const getSamples = this.scorecard.getSamples(samplesBody);
        concat(create, getSamples).subscribe(result => {
          if (result.samples) {
            this.samples = result.samples;
            this.counter.emit(result.counter);
            this.setSamples();
            this.disableFieldsAfterViewInit();

            setTimeout(() => {
              document.getElementById((inputId + 1).toLocaleString('en')).focus();
            }, 0);
          }
        }, error => {
          this.functions.showError(error);
        });
      }
    }

  }

  checkBoxChange($event: any, sampleId, scorecardId, scorecardFieldId, keyword, orderId, sampleTypeId, rowId, inputId) {
    if (this.finalValidate) {
      $event.checked ? this.finalValidateValues.add(sampleId) : this.finalValidateValues.delete(sampleId);
      this.selectedControlSamples.emit(Array.from(this.finalValidateValues));
      return;
    }
    document.getElementById(inputId.toLocaleString('en')).autofocus = true;
    document.getElementById((inputId + 1).toLocaleString('en')).autofocus = true;
    const samplesBody = {
      order_id: orderId,
      sample_type_id: sampleTypeId,
      locale: this.resources.getLanguage().description
    };
    const scoreCardValues = this.findValueBySlug(keyword, sampleId, rowId);
    if (scoreCardValues) {
      const body = {
        scorecard_model_id: scorecardId,
        scorecard_field_id: scorecardFieldId,
        sample_id: sampleId,
        locale: this.resources.getLanguage().description,
        scorecard_value_id: scoreCardValues.id,
        checkbox_value: $event.checked
      };
      const update = this.ordersService.updateAnalyzedField(body);
      const getSamples = this.scorecard.getSamples(samplesBody);
      concat(update, getSamples).subscribe(result => {
        if (result.samples) {
          this.samples = result.samples;
          this.counter.emit(result.counter);
          this.setSamples();
          this.disableFieldsAfterViewInit();

          setTimeout(() => {
            document.getElementById((inputId + 1).toLocaleString('en')).focus();
          }, 0);
        }
      }, error => {
        this.functions.showError(error);
      });
    } else {
      const body = {
        scorecard_model_id: scorecardId,
        scorecard_field_id: scorecardFieldId,
        sample_id: sampleId,
        locale: this.resources.getLanguage().description,
        checkbox_value: $event.checked
      };
      const update = this.ordersService.analyzeField(body);
      const getSamples = this.scorecard.getSamples(samplesBody);
      concat(update, getSamples).subscribe(result => {
        if (result.samples) {
          this.samples = result.samples;
          this.counter.emit(result.counter);
          this.setSamples();
          this.disableFieldsAfterViewInit();

          setTimeout(() => {
            document.getElementById((inputId + 1).toLocaleString('en')).focus();
          }, 0);
        }
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  getFieldValue(slug: string, type, sampleId, rowId): any {
    const scoreCardValues = this.findValueBySlug(slug, sampleId, rowId);
    if (scoreCardValues != null) {
      if (scoreCardValues.scorecard_field_id === 60 ||
        scoreCardValues.scorecard_field_id === 61 ||
        scoreCardValues.scorecard_field_id === 66 ||
        scoreCardValues.scorecard_field_id === 67 ||
        scoreCardValues.scorecard_field_id === 68 ||
        scoreCardValues.scorecard_field_id === 69 ||
        scoreCardValues.scorecard_field_id === 70 ||
        scoreCardValues.scorecard_field_id === 71) {
        return scoreCardValues.text_value;
      }
      if (type === 'text') {
        return scoreCardValues.text_value;
      } else if (type === 'checkbox') {
        return this.finalValidate ? this.allChecked : scoreCardValues.checkbox_value === 1;
      } else if (type === 'dropdown') {
        return scoreCardValues.scorecard_field_option_id;
      }
    } else {
      if (type === 'text') {
        return '';
      } else if (type === 'checkbox') {
        return this.finalValidate ? this.allChecked : false;
      } else if (type === 'dropdown') {
        return 0;
      }
    }
  }

  findSampleById(id): SampleScoreCard {
    return this.samples.find(x => x.id === id);
  }

  findValueBySlug(keyword, id, rowId): ScoreCardValues {
    if (rowId === 1) {
      return this.findSampleById(id).scorecard_value.find(x => x.scorecard_field.slug === keyword && x.row === rowId);

    } else if (rowId > 1) {
      return this.findSampleById(id)[`scorecard_value_${rowId}`].find(x => x.scorecard_field.slug === keyword && x.row === rowId);
    }
  }

  addTemporaryRow(index, sampleId) {
    const sample = this.findSampleById(sampleId);
    const sampleIndex = this.samples.indexOf(sample);
    let count = Math.floor(Math.random());
    this.samples[sampleIndex][`scorecard_value_${index + 1}`] = [];
    this.samples[sampleIndex].number_of_rows++;
    this.temporaryRows.push(`scorecard_value_${index + 1}`);
    this.items.filter(y => y.slug !== 'microscope' && y.slug !== 'remarks').forEach(field => {
      this.idOptions[`${sample.sample_number}-${field.slug}-${index + 1}`] = count;
      count++;
    });
  }

  deleteRow(index, sampleId) {
    const sample = this.samples.find(x => x.id === sampleId);
    const sampleIndex = this.samples.indexOf(sample);
    if (this.temporaryRows.includes(`scorecard_value_${index}`) && sample[`scorecard_value_${index}`].length === 0) {
      this.samples[sampleIndex].number_of_rows--;
      delete this.samples[sampleIndex][`scorecard_value_${index}`];
    } else {
      const deleteBody = {
        sample_id: sampleId,
        locale: this.resources.getLanguage().description
      };
      const samplesBody = {
        order_id: sample.order_id,
        sample_type_id: sample.sample_type_id,
        locale: this.resources.getLanguage().description
      };
      const update = this.ordersService.deleteScoreCardRow(deleteBody);
      const getSamples = this.scorecard.getSamples(samplesBody);
      concat(update, getSamples).subscribe(result => {
        if (result.samples) {
          this.samples = result.samples;
          this.counter.emit(result.counter);
          this.setSamples();
          this.disableFieldsAfterViewInit();

        }
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  checkAll(scorecardField: ScoreCardField, state) {
    const sampleIds = new Array(0);
    const orderId = this.samples[0].order_id;
    const sampleTypeId = this.samples[0].sample_type_id;
    this.samples.forEach(x => {
      sampleIds.push(x.id);
    });
    if (this.finalValidate) {
      this.allChecked = !this.allChecked;
      this.cdr.detectChanges();
      this.selectedControlSamples.emit(sampleIds);
      return;
    }
    const samplesBody = {
      order_id: orderId,
      sample_type_id: sampleTypeId,
      locale: this.resources.getLanguage().description
    };
    const body = {
      scorecard_model_id: scorecardField.scorecard_model_id,
      scorecard_field_id: scorecardField.id,
      sample_ids: sampleIds,
      checkbox_value: !state,
      order_id: orderId,
      locale: this.resources.getLanguage().description
    };
    const checkAll = this.ordersService.checkAll(body);
    const getSamples = this.scorecard.getSamples(samplesBody);
    concat(checkAll, getSamples).subscribe(result => {
      if (result.samples) {
        this.samples = result.samples;
        this.counter.emit(result.counter);
        this.setSamples();
        this.disableFieldsAfterViewInit();

      }
    }, error => {
      this.functions.showError(error);
    });
  }

  getCheckBoxValue(header: ScoreCardField) {
    let items = 0;
    this.samples.forEach(x => {
      x.scorecard_value.forEach(y => {
        if (y.scorecard_field.slug === header.slug && y.checkbox_value === 1) {
          items++;
        }
      });
    });
    return items === this.samples.length;
  }

  onHide($event: string) {
    this.magnificationsLoaded = false;
  }

  change($event: Event) {
  }

  delete(keyword, sampleId: any, rowId: number, orderId, sampleTypeId, inputId) {
    const samplesBody = {
      order_id: orderId,
      sample_type_id: sampleTypeId,
      locale: this.resources.getLanguage().description
    };
    const scoreCardValues = this.findValueBySlug(keyword, sampleId, rowId);
    if (scoreCardValues) {
      const delete$ = this.scorecard.deleteRemarks(scoreCardValues.id);
      const getSamples$ = this.scorecard.getSamples(samplesBody);

      concat(delete$, getSamples$).subscribe(result => {
        if (result.samples) {
          this.samples = result.samples;
          this.counter.emit(result.counter);
          this.setSamples();
          this.disableFieldsAfterViewInit();
          setTimeout(() => {
            document.getElementById((inputId + 1).toLocaleString('en')).focus();
          }, 0);
        }
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  getDifferentElements(array1, array2) {
    // Create Sets from both arrays
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    // Create a new Set to store the differences
    const differences = new Set([...array1.filter(x => !set2.has(x)), ...array2.filter(x => !set1.has(x))]);

    return [...differences];
  }

  deleteFreeOption($event: any, scorecardFieldId, sampleId) {
    this.deleteClicked = true;
    const body = {
      scorecard_field_option_id: $event,
      locale: this.resources.getLanguage().description
    };
    this.scorecard.deleteFreeOption(body).subscribe(res => {
      const item = this.options[`${scorecardFieldId}_${sampleId}`].find(x => x.id === $event);
      const id = this.options[`${scorecardFieldId}_${sampleId}`].indexOf(item);
      this.options[`${scorecardFieldId}_${sampleId}`].splice(id, 1);
      this.deleteClicked = false;
    }, error => {
      this.deleteClicked = false;
      this.functions.showError(error);
    });
  }
}

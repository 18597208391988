import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdminStatics } from '../../../admin/statics/admin-statics';
import { FunctionsService } from '../../../admin/services/functions.service';
import { ResourcesService } from '../../../shared/services/resources.service';
import { StorageUser } from '../../../models';

@Component({
  selector: 'app-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class PaginationListComponent implements OnInit, OnChanges {

  @Input() items: any[];
  @Input() headers: any[];
  @Input() activeItem: number;
  @Input() totalItems;
  @Input() listType;
  @Input() routerLinkDetails;
  @Input() isAnalyst: boolean;

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  sortHeaderValues = [];
  page = 0;
  numberRows = 10;

  displayModal = false;
  itemToDelete = null;
  storageUser: StorageUser;

  constructor(private router: Router, private resources: ResourcesService) {
  }

  ngOnInit() {
    this.storageUser = this.resources.getStorageUser();
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });
  }

  ngOnChanges() {}

  sortByLabel(param: string) {
    this.clearOtherValues(param);
    const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
    sortValue.asc = !sortValue.asc;

    this.sortValue.emit(sortValue);
  }

  deleteItem() {
    this.itemDelete.emit(this.itemToDelete);
    this.displayModal = false;
    this.itemToDelete = null;
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  onItemClicked(item) {
    this.itemClicked.emit(item);
  }

  navigate(id) {
    if (this.routerLinkDetails && this.activeItem !== id) {
      this.router.navigate([this.routerLinkDetails, id]);
    }

    if (this.routerLinkDetails && this.activeItem === id) {
      this.router.navigateByUrl('/admin/profile');
    }
  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }
}

<p-card>
  <div class="p-grid spacing-bottom">
    <div class="p-col-6 role-main-title">
      <p>{{'ROLE' | translate}}</p>
    </div>
  </div>
  <form [formGroup]="roleForm" class="pdg-bottom">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <app-prolabo-dropdown [options]="roles"
                              (OnValueChange)="updateRoleValue($event)"
                              [defaultValue]="user.role_id"
                              [disabled]="isAnalyst"
                              placeholder="ROLE">
        </app-prolabo-dropdown>
      </div>
    </div>
  </form>
</p-card>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FunctionsService } from '../../../../admin/services/functions.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminStatics } from '../../../../admin/statics/admin-statics';
import { Laboratory } from '../../../../models/laboratorie';

@Component({
  selector: 'app-prolabo-analyst-orders-pagination-list',
  templateUrl: './analyst-orders-pagination-list.component.html',
  styleUrls: ['./analyst-orders-pagination-list.component.scss']
})
export class AnalystOrdersPaginationListComponent implements OnInit, OnChanges {
  @Input() items: any[];
  @Input() itemValues: any;
  @Input() headers: any[];
  @Input() totalItems;
  @Input() total;
  @Input() routerLinkDetails;
  @Input() filter: number;
  @Input() filterItems: [];
  @Input() laboratories: Laboratory[];

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterBy: EventEmitter<number> = new EventEmitter<number>();
  @Output() filterDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterByOrderState: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() filterByOrderCity: EventEmitter<string[]> = new EventEmitter<string[]>();


  sortHeaderValues = [];
  filterValues = [];
  filterByState = [];
  filterByCity = [];
  page = 0;
  numberRows = 10;
  stateIsSelected = false;

  totalSteps = 4;

  displayModal = false;
  itemToDelete = null;
  cityIsSelected = false;
  sampleIsSelected = false;
  selectedSample: FormControl = new FormControl();
  selectedState: FormControl = new FormControl();
  selectedCity: FormControl = new FormControl();

  constructor(private router: Router, public functions: FunctionsService, private translate: TranslateService) {
  }

  ngOnInit() {
    this.headers.forEach(header => {
      this.sortHeaderValues.push({param: header[AdminStatics.sortLabel], asc: true});
    });

    this.filterByState = [
      {text: this.translate.instant('ALL'), id: -1, value: -1},
      {text: this.translate.instant('CREATION'), id: 0, value: 0},
      {text: this.translate.instant('ORDERED'), id: 1, value: 1},
      {text: this.translate.instant('ANALYSING'), id: 2, value: 2},
      {text: this.translate.instant('ANALYSED'), id: 3, value: 3},
      {text: this.translate.instant('FINISHED'), id: 4, value: 4},
      {text: this.translate.instant('CANCELLED'), id: 5, value: 5}
    ];

    this.cityIsSelected = this.selectedCity.value !== -1 && this.selectedCity.value;
    this.sampleIsSelected = this.selectedSample.value !== -1 && this.selectedSample.value;
  }

  ngOnChanges() {
    if (this.filterItems && this.filterItems.length > 0) {
      this.getFilterItemValues();
    }
    if (this.laboratories && this.laboratories.length > 0) {
      this.filterByCity = new Array(0);
      this.filterByCity.push({text: this.translate.instant('ALL'), id: -1, value: -1});
      this.laboratories.forEach(x => {
        this.filterByCity.push({text: x.city, id: x.id, value: x.id});
      });
    }

    this.cityIsSelected = this.selectedCity.value !== -1 && this.selectedCity.value;
    this.sampleIsSelected = this.selectedSample.value !== -1 && this.selectedSample.value;
  }

  getFilterItemValues() {
    this.filterValues = [];
    this.filterValues.push({id: -1, text: this.translate.instant('NONE')});
    this.filterItems.forEach((item: any) => {
      switch (item.id) {
        case 6:
          this.filterValues.push({id: item.id, text: this.translate.instant('QUANTITATIVE_BUFFER')});
          break;
        case 7:
          this.filterValues.push({id: item.id, text: this.translate.instant('QUALITATIVE_BUFFER')});
          break;
        default:
          this.filterValues.push({id: item.id, text: this.translate.instant(String(item.slug).toUpperCase())});
          break;
      }
    });
  }

  getTotalItemName(name: string) {
    let result;

    switch (name) {
      case 'Quantitative buffer':
        result = this.translate.instant('QUANTITATIVE_BUFFER');
        break;
      case 'Qualitative buffer':
        result = this.translate.instant('QUALITATIVE_BUFFER');
        break;
      default:
        result = this.translate.instant(name.toUpperCase());
        break;
    }

    return result;
  }

  sortByLabel(param: string) {
    if (param !== 'latest_state' && param !== 'city') {
      this.clearOtherValues(param);
      const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
      sortValue.asc = !sortValue.asc;
      this.sortValue.emit(sortValue);
    }
  }

  handleFilterByStateDropdownChange(event) {
    this.filterByOrderState.emit(event.value);
  }

  handleFilterByCityDropdownChange(event) {
    this.filterByOrderCity.emit(event.value);
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }


  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  navigate(id, type, state) {
    if (state !== 6) {
      if (type === AdminStatics.simpleOrder) {
        this.router.navigate(['/analyst/orders/simple-order', id]);
      }
      if (type === AdminStatics.diagnosticOrder) {
        this.router.navigate(['/analyst/orders/diagnostic-order', id]);
      }
    }
  }

  deleteFilter() {
    this.filterDelete.emit(true);
  }

  handleFilterDropdownChange(event) {
    this.filterBy.emit(event.value);
  }
}

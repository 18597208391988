import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminSimpleOrdersService} from 'src/app/admin/services/admin-simple-orders.service';
import {ToastService, UserService} from 'src/app/shared/services';
import {Client} from 'src/app/admin/models/client';
import {DialogService} from 'primeng/dynamicdialog';
import {
  SampleCreateModalComponent
} from 'src/app/admin/pages/orders/modals/sample-create-modal/sample-create-modal.component';
import {Type} from 'src/app/admin/models/type';
import {DropdownModel} from 'src/app/shared/components/dropdown/dropdown.model';
import {FormGroup} from '@angular/forms';
import {
  StepTwoModalSimpleComponent
} from 'src/app/admin/pages/orders/modals/step-two-modal-simple/step-two-modal-simple.component';
import {forkJoin} from 'rxjs';
import {User} from 'src/app/models';
import {TranslateService} from '@ngx-translate/core';
import {ScoreCardModalComponent} from '../../../modals/score-card-modal/score-card-modal.component';
import {ResourcesService} from '../../../../../../shared/services/resources.service';
import {AdminStatics} from '../../../../../statics/admin-statics';
import {StepCounter} from '../../../../../models/step-counter';
import {AdminSimpleSamplesService} from '../../../../../services/admin-simple-samples.service';
import {SimpleOrder, SimpleSample} from '../../../../../models/simple-order-response';
import {AdminOrdersService} from '../../../../../services/admin-orders.service';
import {AdminScorecardModelService} from '../../../../../services/admin-scorecard-model.service';
import {
  StepThreeModalDiagnosticComponent
} from '../../../modals/step-three-diagnostic-modal/step-three-diagnostic-modal.component';
import {environment} from '../../../../../../../environments/environment';
import {NewVersionModalComponent} from '../../../modals/new-version-modal/new-version-modal.component';
import {ScoreCardModel} from '../../../../../models/score-card-model';
import {AdminAnalystCreatorService} from '../../../../../../common/services/admin-analyst-creator.service';
import {DatePipe} from '@angular/common';
import {Deadline} from '../../../../../../models/deadline';
import {FunctionsService} from '../../../../../services/functions.service';
import {RegenerateInvoiceComponent} from '../../../modals/regenerate-invoice/regenerate-invoice.component';
import {BexioService} from '../../../../../../common/services/bexio.service';
import {InvoiceType, Laboratory} from '../../../../../../models/laboratorie';
import {AdminDashboardService} from '../../../../../services/admin-dashboard.service';
import {UpdateDateModalComponent} from '../../../modals/update-date-modal/update-date-modal.component';

@Component({
  selector: 'app-prolabo-simple-order-container',
  templateUrl: './simple-order-container.component.html',
  styleUrls: ['./simple-order-container.component.scss']
})
export class SimpleOrderContainerComponent implements OnInit {


  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private analystCreator: AdminAnalystCreatorService,
              private adminOrdersService: AdminOrdersService,
              private toast: ToastService,
              private router: Router,
              private datePipe: DatePipe,
              private user: UserService,
              private translate: TranslateService,
              private resources: ResourcesService,
              private orders: AdminOrdersService,
              private samplesService: AdminSimpleSamplesService,
              private scorecard: AdminScorecardModelService,
              public functions: FunctionsService,
              private bexio: BexioService,
              private ordersService: AdminSimpleOrdersService,
              private dashboardService: AdminDashboardService) {
    translate.onLangChange.subscribe(x => {
      this.setTypes(this.originalTypes);
    });
  }

  order: SimpleOrder;
  clients: Client[];
  selectedClient: Client;
  types: DropdownModel[] = [];
  originalTypes: Type[];
  magnifications: DropdownModel[] = [];
  isCanceled = false;
  samplesHeader = [
    {title: '', sortLabel: null},
    {title: 'SAMPLE.ID', sortLabel: AdminStatics.id},
    {title: 'SAMPLE.NUMBER', sortLabel: AdminStatics.firstName},
    {title: 'SAMPLE.DESCRIPTION', sortLabel: AdminStatics.company}
  ];
  samples: SimpleSample[] = new Array(0);
  samplesByType = [];
  deadlines: Record<string, DropdownModel[]> = {};
  initiatedSubmit = false;
  clientAdded = false;
  samplesAdded = false;
  isAnalyst: boolean;
  sampleForm = new FormData();
  step1: boolean;
  step2: boolean;
  step3: boolean;
  step4: boolean;
  stepDone: boolean;
  orderId: number;
  stepCounter1: StepCounter;
  stepCounter2: StepCounter;
  stepCounter3: StepCounter;
  stepCounter4: StepCounter;
  error = false;
  currentUser: User;
  scorecardModels: ScoreCardModel[];
  getOrderInitiated = false;
  numGeneratedInvoices = 0;
  generatedInvoicesDate = null;
  generatedReportDate = null;
  generatedEmailDate = null;
  displayModal = false;
  displayModal2 = false;
  laboratories: DropdownModel[];
  deadlinesKeys: string[];
  invoiceTypes: DropdownModel[] = [];
  defaultDeadlineValue: number;

  public readonly Object = Object;

  ngOnInit(): void {
    this.getScoreCardModels();
    this.currentUser = this.user.getCurrentUser();
    if (this.resources.getInvoiceTypes()) {
      this.setInvoiceTypes(this.resources.getInvoiceTypes());
    } else {
      this.setInvoiceTypes(this.resources.backupInvoiceTypes);
    }
    this.route.paramMap.subscribe(snapshot => {
      this.orderId = +snapshot.get(AdminStatics.id);
      this.getOrderDetails(this.orderId).then(() => {
      });
    });
  }

  private setInvoiceTypes(invoiceTypes: InvoiceType[]) {
    invoiceTypes.forEach(invoiceType => {
      this.invoiceTypes.push({id: invoiceType.id, text: invoiceType.slug, value: invoiceType.id});
    });
  }

  openScoreCard(samples: any[], isAnalysed, finalValidate) {
    const ref = this.dialogService.open(ScoreCardModalComponent, {
      showHeader: false,
      width: '90%',
      data: {
        order_id: this.order.id,
        samples,
        orderRemark: this.order.remarks,
        orderNumber: this.order.order_number,
        orderDate: this.order.created_at,
        laboratoryId: this.order.laboratory_id,
        order: this.order,
        finalValidate,
        dropdownOptions: this.magnifications
      },
    });
    ref.onClose.subscribe(form => {
      if (form) {
        if (finalValidate) {
          const body = {
            locale: this.resources.getLanguage().description,
            order_id: this.orderId,
            send_email: false,
            sample_ids: form
          };
          this.ordersService.submitStep3(body).subscribe(result => {
            this.toast.showBottomCenterInfo(result.message);
            this.getOrderDetails(this.orderId).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        } else {
          if (isAnalysed) {
            this.scorecard.updateScoreCard(form).subscribe(result => {
              this.toast.showBottomCenterInfo(result.message);
              this.getOrderDetails(this.order.id).then(() => {
              });
            }, error => {
              this.functions.showError(error);
            });
          } else {
            this.scorecard.validateScoreCard(form).subscribe(result => {
              this.toast.showBottomCenterInfo(result.message);
              this.getOrderDetails(this.order.id).then(() => {
              });
            }, error => {
              this.functions.showError(error);
            });
          }
        }
      } else {
        this.getOrderDetails(this.order.id).then(() => {
        });
      }
    });
  }

  createSample(sampleTypeId: number = null) {
    const body = {
      order_id: this.order.id,
      sample_type_id: sampleTypeId ? sampleTypeId : this.types[1].id,
      no_prolabo: false,
      locale: this.resources.getLanguage().description
    };

    this.orders.getNextSampleId(body).subscribe(id => {
      let data;
      if (this.order.samples?.prolabo != null) {
        data = {
          order: this.order, types: this.types,
          sample_type_id: Object.keys(this.order.samples.prolabo),
          next_sample_id: id.next_sample_id,
          disabled: true,
          state: 'create'
        };
      } else {
        data = {
          order: this.order, types: this.types,
          sample_type_id: sampleTypeId ? sampleTypeId : this.types[1].id,
          next_sample_id: id.next_sample_id,
          disabled: false,
          state: 'create'
        };
      }
      const ref = this.dialogService.open(SampleCreateModalComponent, {
        showHeader: false,
        width: '60%',
        data
      });

      ref.onClose.subscribe(form => {
        if (form) {
          this.samplesService.createSample(form).subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.samplesAdded = true;
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  public submitOrder() {
    this.initiatedSubmit = true;
    setTimeout(() => {
      if (!this.error) {
        // @ts-ignore
        this.sampleForm.append('order_id', this.order.id);
        this.sampleForm.append('locale', this.resources.getLanguage().description);
        setTimeout(() => {
          this.ordersService.submitOrder(this.sampleForm).subscribe(result => {
            this.toast.showBottomCenterSuccess(result.message);
            if(result.url){
              window.open(environment.storageUrl + result.url, '_blank');
            }
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.clientAdded = false;
            this.initiatedSubmit = false;
            this.sampleForm = new FormData();
          }, error => {
            this.functions.showError(error);
            this.sampleForm = new FormData();
            this.initiatedSubmit = false;
          });
        }, 100);
      }
    }, 100);
  }

  public handleClientEvent($event: Client) {
    this.selectedClient = $event;
    if ($event) {
      this.clientAdded = true;
    }
  }

  public handleDelete($event: any) {
    const body = {
      sample_id: $event,
      locale: this.resources.getLanguage().description
    };
    this.samplesService.deleteSample(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.orderId).then(() => {
        const sample = this.samples.find(s => s.id === $event);
        const index = this.samples.indexOf(sample, 0);
        if (index > -1) {
          this.samples.splice(index, 1);
        }
        this.samplesAdded = Object.keys(this.order.samples).length > 0;
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  public cancelOrder() {
    const body = {
      id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.displayModal2 = false;
    this.ordersService.cancelOrder(body).subscribe(result => {
      if (this.isAnalyst) {
        this.router.navigateByUrl('/analyst/orders').then(() => {
          this.toast.showBottomCenterSuccess(result.message);
        });
      } else {
        this.router.navigateByUrl('/admin/orders').then(() => {
          this.toast.showBottomCenterSuccess(result.message);
        });
      }
    }, error => {
      this.functions.showError(error);
    });
  }

  private setLaboratories(laboratories: Laboratory[]) {
    this.laboratories = new Array(0);
    laboratories.forEach(laboratory => {
      this.laboratories.push({id: laboratory.id, text: laboratory.city, value: laboratory.id});
    });
  }

  public reActivateOrder() {
    const body = {
      id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.displayModal = false;
    this.ordersService.reActivateOrder(body).subscribe(result => {
      this.toast.showBottomCenterSuccess(result.message);
      this.getOrderDetails(this.order.id).then(() => {
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  openConfirmDialog() {
    this.displayModal = true;
  }

  closeConfirmDialog() {
    this.displayModal = false;
  }

  openConfirmDialog2() {
    this.displayModal2 = true;
  }

  closeConfirmDialog2() {
    this.displayModal2 = false;
  }

  downloadTemplate() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    this.ordersService.downloadExcelTemplate(body).subscribe(x => {
      window.open(`${environment.storageUrl}${x.template}`);
    });
  }

  uploadTemplate(event) {
    const formData = new FormData();
    let reader;
    if (event.target.files && event.target.files[0]) {
      Array.from(event.target.files).forEach((file: any) => {
        reader = new FileReader();
        // tslint:disable-next-line:no-shadowed-variable
        reader.onload = (event: ProgressEvent) => {
          const fileInBlob = new File([this.dataURLToBlob((event.target as FileReader).result)],
            'template', {type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`});
          formData.append(`template`, fileInBlob);
          // @ts-ignore
          formData.append('order_id', this.order.id);
          formData.append('locale', this.resources.getLanguage().description);

          this.ordersService.importExcelTemplate(formData).subscribe(x => {
            this.toast.showBottomCenterSuccess(x.message);
            // @ts-ignore
            document.getElementById('import').value = null;
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            // @ts-ignore
            document.getElementById('import').value = null;
            this.functions.showError(error);
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  public handleOrderFormEvent(form: FormGroup) {
    const remarks = 'remarks';
    if (form.value[remarks] === '') {
      delete form.value[remarks];
    }
    if (this.clientAdded) {
      this.toFormData(form.value);
    } else {
      this.toFormData(form.value, true);
    }
  }

  public handleEmailEvent($event: string[]) {
    // @ts-ignore
    for (const email of $event) {
      this.sampleForm.append('email[]', email);
    }
  }

  public handleLanguageEvent($event: number) {
    // @ts-ignore
    this.sampleForm.append('lang_id', $event);
    this.sampleForm.append('locale', this.resources.getLanguage().description);
  }

  public submitStep2() {
    this.initiatedSubmit = true;
    setTimeout(() => {
      if (!this.error) {
        // @ts-ignore
        this.sampleForm.append('order_id', this.order.id);
        setTimeout(() => {
          const corruptedSamplesBody = {
            order_id: this.order.id,
            locale: this.resources.getLanguage().description
          };
          this.ordersService.getCorruptedSamples(corruptedSamplesBody).subscribe(result1 => {
            const ref = this.dialogService.open(StepTwoModalSimpleComponent, {
              showHeader: false,
              width: '60%',
              data: {samples: result1.samples, headers: this.samplesHeader}
            });
            ref.onClose.subscribe(result => {
              if (result) {
                const body = {
                  order_id: this.order.id,
                  send_email: result.email,
                  locale: this.resources.getLanguage().description,
                  sample_ids: result.ids
                };
                this.ordersService.submitStep2(body).subscribe(res => {
                  if (res.url && Object.keys(this.order.samples.prolabo)[0] !== '8') {
                    window.open(environment.storageUrl + res.url, '_blank');
                  }
                  this.getOrderDetails(this.order.id).then(() => {
                  });
                  this.toast.showBottomCenterSuccess(res.message);
                  this.sampleForm = new FormData();
                }, error => {
                  this.functions.showError(error);
                  this.sampleForm = new FormData();
                  this.initiatedSubmit = false;
                });
              }
            });
          });
        }, 100);
      }
    }, 100);
  }

  submitStep4() {
    const body = {
      locale: this.resources.getLanguage().description,
      order_id: this.orderId,
      send_email: false
    };
    this.ordersService.submitStep3(body).subscribe(result => {
      this.toast.showBottomCenterInfo(result.message);
      this.getOrderDetails(this.orderId).then(() => {
      });
    }, error => {
      this.functions.showError(error);
    });
  }

  private toFormData<T>(formValue: T, shouldRemove?): void {
    if (shouldRemove) {
      for (const key of Object.keys(formValue)) {
        if (key === 'client_id') {
          continue;
        }
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    } else {
      for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        this.sampleForm.append(key, value);
      }
    }
  }

  public getOrderDetails(id): Promise<boolean> {
    this.isAnalyst = this.resources.getStorageUser().role === 'analyst';
    this.getOrderInitiated = false;
    this.isCanceled = false;
    return new Promise<boolean>((resolve => {
      const body = {
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const typeBody = {
        order_type_id: 1,
        order_id: id,
        locale: this.resources.getLanguage().description
      };
      const order = this.ordersService.getOrder(body);
      const sampleTypes = this.orders.getSampleTypes(typeBody);
      const dropdownOptions = this.scorecard.getDropdownOptions({locale: this.resources.getLanguage().description});
      const laboratories = this.dashboardService.getLaboratories();
      forkJoin([order, sampleTypes, dropdownOptions, laboratories]).subscribe(result => {
          this.adminOrdersService.getDeadlines(body).subscribe(deadlines => {
            this.getOrderInitiated = true;
            this.order = null;
            this.order = result[0].order;
            this.isCanceled = this.order.latest_state.name === 'admin.cancelled';
            this.defaultDeadlineValue = this.order.deadlines ? this.order.deadlines[0]?.deadline_id : null;
            this.numGeneratedInvoices = this.order.invoices?.invoice ? 1 : 0;
            this.getGeneratedReportDate();
            this.checkOrderValidity(this.order.latest_state.name, this.order.state);
            this.samples = new Array(0);
            if (result[0].order.samples?.prolabo) {
              for (const prolaboEntries of Object.keys(result[0].order.samples.prolabo)) {
                this.samplesByType = Object.entries(result[0].order.samples.prolabo);

                if (Object.entries(result[0].order.samples.prolabo)[0]) {
                  this.samplesAdded = true;
                }
                if (result[0].order.samples.prolabo) {
                  for (const s of Object.keys(result[0].order.samples.prolabo)) {
                    for (const finalSample of result[0].order.samples.prolabo[s].samples) {
                      const existing = this.samples.find(x => x.id === finalSample.id);
                      if (existing == null) {
                        this.samples.push(finalSample);
                      }
                    }
                  }
                }
              }
            } else {
              this.samplesByType = new Array(0);
            }
            this.setTypes(result[1]);
            this.setDeadlines(deadlines.deadlines);
            this.setMagnifications(result[2].options);
            this.setLaboratories(result[3].laboratories);
            resolve(true);
          });
        }, error => {
          this.functions.showError(error);
        }
      );
    }));
  }

  getScoreCardModelHeaders(sampleTypeId: string) {
    return this.scorecardModels.find(x => x.sample_type_id === Number(sampleTypeId)).scorecard_field.filter(x => x.slug !== 'filtration' && x.slug !== 'preparation');
  }

  private setTypes(types: Type[]) {
    this.originalTypes = types;
    this.types = [{id: 0, text: 'SELECT', value: 0}];
    if (types) {
      Object.entries(types).map(sample => {
        this.types.push({
          id: sample[1].id,
          text: sample[1][this.translate.currentLang],
          value: sample[1].id
        });
      });
    }
  }

  private setMagnifications(types: any[]) {
    this.magnifications = [{id: 0, text: '', value: 0}];
    if (types) {
      Object.entries(types).map(sample => {
        this.magnifications.push({
          id: sample[1].id,
          text: sample[1].value,
          value: sample[1].id
        });
      });
    }
  }

  getGeneratedReportDate() {
    this.generatedReportDate = null;
    this.generatedEmailDate = null;
    if (this.order.action && this.order.action.length > 0) {
      // @ts-ignore
      const report = this.order.action.find(action => action.name === AdminStatics.actionsGeneratedReports);
      if (report) {
        // @ts-ignore
        this.generatedReportDate = this.datePipe.transform(report.pivot?.updated_at, 'dd.MM.yy');
      }
      // @ts-ignore
      const email = this.order.action.find(action => action.name === AdminStatics.emailSent);

      if (email) {
        // @ts-ignore
        this.generatedEmailDate = this.datePipe.transform(email.pivot?.updated_at, 'dd.MM.yy HH:mm:ss');
      }
    }
  }

  getScoreCardModels() {
    this.analystCreator.getScoreCardModels(this.orderId).subscribe(result => {
      this.scorecardModels = result.models;
    });
  }

  private checkOrderValidity(orderName, state) {
    switch (orderName) {
      case 'admin.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.cancelled':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.creating':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.preveling':
        this.step1 = true;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeDefault(1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.ordered':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.sent':
        this.step1 = false;
        this.step2 = true;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeDefault(2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'client.inprocess':
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeDefault(3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.analyzed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = true;
        this.stepDone = false;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeDefault(4, 'FINISHED');
        break;
      case 'admin.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
      case 'client.completed':
        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.stepDone = true;
        this.stepCounter1 = this.initializeStep(state, 1, 'ORDERED');
        this.stepCounter2 = this.initializeStep(state, 2, 'ANALYSING');
        this.stepCounter3 = this.initializeStep(state, 3, 'ANALYSED');
        this.stepCounter4 = this.initializeStep(state, 4, 'FINISHED');
        break;
    }
  }

  private initializeStep(state, step, label): StepCounter {
    return {
      button: 'button-selected',
      label,
      icon: 'icon-number-selected',
      number: step,
      date: state[step]?.pivot.updated_at
    };
  }


  // @ts-ignore
  private initializeDefault(step, label): StepCounter {
    return {
      number: step,
      icon: 'icon-number-unselected',
      button: 'button-unselected',
      label,
      dateLabel: 'WAITING'
    };
  }

  private validateForm(formData: FormData): Promise<boolean> {
    return new Promise<boolean>((resolve => {
      this.ordersService.updateOrder(formData).subscribe(result => {
        this.getOrderDetails(this.order.id).then(() => {
        });
        resolve(true);
      }, error => {
        this.functions.showError(error);
        this.sampleForm = new FormData();
        this.initiatedSubmit = false;
      });
    }));
  }

  handleEmailError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.EMAIL'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  handleLangError($event: boolean) {
    if ($event) {
      this.error = true;
      this.toast.showBottomCenterError(this.translate.instant('FORM.LANG'));
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  handleTarifsFormEvent($event: FormGroup) {
    this.toFormData($event.value);
  }

  handleOrderError($event: any) {
    if ($event.length > 0) {
      this.error = true;
      $event.forEach(x => {
        this.toast.showBottomCenterError(this.translate.instant(x));
      });
      this.initiatedSubmit = false;
    } else {
      this.error = false;
    }
  }

  createNewVersion() {
    const ref = this.dialogService.open(NewVersionModalComponent, {
      showHeader: false,
      width: '80%',
      data: {order: this.orderId}
    });
    ref.onClose.subscribe(result => {
      if (result) {
        this.ordersService.createNewVersion(result).subscribe(res => {
          this.toast.showBottomCenterSuccess(res.message);
          const body = {
            id: this.order.id,
            locale: this.resources.getLanguage().description
          };
          this.ordersService.cancelOrder(body).subscribe(x => {
            if (this.isAnalyst) {
              this.router.navigateByUrl('/analyst/orders/simple-order/' + res.order.id)
                .then(() => {
                  this.stepDone = false;
                  this.step3 = false;
                  this.step4 = false;
                });
            } else {
              this.router.navigateByUrl('/admin/orders/simple-order/' + res.order.id)
                .then(() => {
                  this.stepDone = false;
                  this.step3 = false;
                  this.step4 = false;
                });
            }
          });
        }, error => {
          this.functions.showError(error);
        });
      }
    });
  }

  printClicked() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };
    if ((this.order.pdf_report_path == null && this.order.latest_state_id === 5) ||
      (this.order.pdf_report_path == null && this.order.latest_state_id === 10)) {
      this.ordersService.getReportStatus(body).subscribe(result => {
        this.toast.showBottomCenterInfo(result.message);
        if (result.url) {
          this.downloadAndOpenPdf(result.url);
          //window.open(environment.storageUrl + result.url, '_blank');
          window.location.reload();
        }
        this.getOrderDetails(this.order.id).then(() => {
        });
      }, error => {
        this.functions.showError(error);
      });
    } else {
      this.downloadAndOpenPdf(environment.storageUrl + this.order.pdf_report_path);
      // window.open(environment.storageUrl + this.order.pdf_report_path, '_blank');
    }
  }

  generatePdfName(): string {
    const orderNumber = this.order.order_number;
    const site = this.order.site;
    const combinedString = `${orderNumber}-${site}`;

    // Replace non-alphanumeric characters with hyphens
    const sanitizedString = combinedString.replace(/[^a-zA-Z0-9_-]+/g, '-');

    // Trim the string to a maximum of 250 characters
    return sanitizedString.substring(0, 250);
  }

  async downloadAndOpenPdf(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.log('Network response was not ok');
      }
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      // Open PDF in a new tab
      window.open(objectUrl);

      // If you also want to download the file locally
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = this.generatePdfName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Cleanup
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error('Download error:', error);
    }
  }

  sendReportByMail(value) {
    if(this.order.sosamiante_order_uuid != null){
      const body = {
        order_id: this.order.id,
        locale: this.resources.getLanguage().description,
      };
      this.ordersService.sendReport(body).subscribe(result => {
        this.toast.showBottomCenterSuccess(result.message);
        this.getOrderDetails(this.order.id).then(() => {
        });
      }, error => {
        this.functions.showError(error);
      });
    } else {
      const ref = this.dialogService.open(StepThreeModalDiagnosticComponent, {
        showHeader: false,
        width: '80%',
        data: {order: this.order, reportByMail: true}
      });
      ref.onClose.subscribe(res => {
        if (res) {
          res.after_work_version = value;
          this.ordersService.sendReport(res).subscribe(result => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    }
  }

  regenerateReport() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description,
      regenerate_report: 1,
    };
    this.ordersService.getReportStatus(body).subscribe(result => {
      this.getOrderDetails(this.order.id).then(() => {
      });
      this.toast.showBottomCenterInfo(result.message);
    }, error => {
      this.functions.showError(error);
    });
  }

  handleSampleUpdate(sampleId: number) {
    const ref = this.dialogService.open(SampleCreateModalComponent, {
      showHeader: false,
      width: '60%',
      data: {
        order: this.order,
        types: this.types,
        sample: this.samples.find(x => x.id === sampleId),
        samples: this.samples,
        disabled: true,
        state: 'update'
      }
    });

    ref.onClose.subscribe(data => {
      if (data) {
        this.samplesService.updateSampleRegular(data)
          .subscribe((result: any) => {
            this.toast.showBottomCenterSuccess(result.message);
            this.getOrderDetails(this.order.id).then(() => {
            });
          }, error => {
            this.functions.showError(error);
          });
      }
    });
  }

  updateDeadlineValue($event: string, sampleTypeId: any) {
    const body: any = {
      order_id: this.order.id,
      deadline_id: $event,
      locale: this.resources.getLanguage().description,
      sample_type_id: +sampleTypeId
    };
    if ($event.toString() === '9') {
      const ref = this.dialogService.open(UpdateDateModalComponent, {
        showHeader: false,
        width: '33%',
      });

      ref.onClose.subscribe(res => {
        if (res) {
          body.date = res;
          this.adminOrdersService.modifyDeadline(body).subscribe(result => {
            this.getOrderDetails(this.order.id).then(() => {
            });
            this.toast.showBottomCenterInfo(result.message);
          }, error => {
            this.functions.showError(error);
          });
        }
      });
    } else {
      this.adminOrdersService.modifyDeadline(body).subscribe(result => {
        this.getOrderDetails(this.order.id).then(() => {
        });
        this.toast.showBottomCenterInfo(result.message);
      }, error => {
        this.functions.showError(error);
      });
    }
  }

  public getDeadline(deadlineId, sampleTypeId): DropdownModel {
    return this.deadlines[sampleTypeId].find(x => x.id === deadlineId);
  }

  private setDeadlines(types: any) {
    const entries = Object.entries(types);
    // tslint:disable-next-line:forin
    for (const type in entries) {
      this.deadlines[entries[type[0]][0]] = this.setDeadlineByType(entries[type[0]][1]);
    }
  }

  private setDeadlineByType(types: Deadline[]) {
    const deadlines = new Array(0);
    Object.values(types).forEach(x => {
      deadlines.push({
        id: x.id,
        text: x.slug,
        value: x.id
      });
    });

    return deadlines;
  }

  generateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.generateInvoice(body).subscribe(result => {
      if (result.open_modal) {
        const ref = this.dialogService.open(RegenerateInvoiceComponent, {
          showHeader: false,
          width: '90%',
          data: result.message
        });

        ref.onClose.subscribe(res => {
          if (res) {
            this.regenerateInvoice();
          }
        });
      } else {
        this.toast.showBottomCenterInfo(result.message);
        this.getOrderDetails(this.order.id).then(() => {
        });
      }
    }, error => this.functions.showError(error));
  }


  regenerateInvoice() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.bexio.reGenerateInvoice(body).subscribe(result => {
      this.toast.showBottomCenterInfo(result.message);
      this.getOrderDetails(this.order.id).then(() => {
      });
    }, error => this.functions.showError(error));
  }

  printInvoice() {
    this.functions.showPdf(this.order.invoices.invoice.content, this.order.order_number);
  }

  openEtiquets(codes: number) {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description,
      codes: Object.keys(this.order.samples.prolabo)[0] === '2' ? codes : 0
    };
    this.ordersService.openLabels(body).subscribe(x => {
      window.open(environment.storageUrl + x.url, '_blank');
    }, error => this.functions.showError(error));
  }

  exportSamples() {
    const body = {
      order_id: this.order.id,
      locale: this.resources.getLanguage().description
    };

    this.ordersService.exportSamples(body).subscribe(result => {
      window.open(environment.storageUrl + result.template, '_blank');
    }, error => {
      this.functions.showError(error);
    });
  }

  getTranslation(translate: any) {
    return this.order.deadline_at != null ?
      translate.replace('DEADLINE.EIGHT.DATE', this.datePipe.transform(this.order.deadline_at, 'd.MM.yyyy')) :
      translate.replace('DEADLINE.EIGHT.DATE', this.translate.instant('DEADLINE.EIGHT.DATE'));
  }

  openLink() {
    window.open('http://sos-aminante.ch/', '_blank');
  }
}

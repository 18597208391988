<div class="clients-container">
  <app-dialog-header [title]="'NEW_ORDER'" (iconClick)="ref.close()"></app-dialog-header>
  <div class="p-grid" *ngIf="isUser && !next">
    <div class="p-col-12 p-xl-4 p-lg-4 p-md-4">
      <app-prolabo-dropdown [options]="sampleTypes"
                            (OnValueChange)="updateSampleTypeEvent($event)"
                            [inputId]="sampleTypeId"
                            [defaultValue]="sampleTypeId"
                            class="dropdown-item"
                            type="test"
                            placeholder="TYPE">
      </app-prolabo-dropdown>
    </div>
    <ng-container *ngIf="isAsbestos">
      <div class="p-col-12 p-xl-4 p-lg-4 p-md-4">
        <app-prolabo-dropdown [options]="deadlines"
                              (OnValueChange)="updateDeadlineEvent($event)"
                              [inputId]="deadlineId"
                              [defaultValue]="deadlineId"
                              class="dropdown-item"
                              placeholder="DEADLINE">
        </app-prolabo-dropdown>
      </div>
    </ng-container>
    <div class="p-col-12 p-xl-4 p-lg-4 p-md-4">
      <app-prolabo-dropdown [options]="laboratories"
                            (OnValueChange)="updateLaboratoriesEvent($event)"
                            [defaultValue]="laboratoryId"
                            [inputId]="laboratoryId"
                            #laboratory
                            [disabled]="sampleTypeId==null"
                            class="dropdown-item"
                            type="test"
                            placeholder="BRANCH">
      </app-prolabo-dropdown>
    </div>
    <div class="p-col-12 " style="padding-top:30px">
      {{'NOTE' | translate}}
    </div>
  </div>
  <div class="p-grid" *ngIf="!isUser && !next && users.length > 0 ">
    <div class="p-col-12" style="padding-bottom: 200px">
      <app-dropdown-select
        [options]="users"
        (OnValueChange)="updateUserId($event)"
        (OnFilter)="onFilter($event)"
        [defaultValue]="1"
        [offset]="limit"
        placeholder="ORDER.OWNER"></app-dropdown-select>
    </div>
  </div>
  <!--  <div class="p-grid" *ngIf="(isUser && next && laboratoryId !== -1) || !isUser">-->
  <!--    <div class="p-col-12 p-md-6">-->
  <!--      <p-card (click)="createSimple()">-->
  <!--        <img src="../../../../../../assets/icons/shopping-basket.svg" alt="">-->
  <!--        <p class="main-text">{{'SIMPLE.ORDER' | translate}}</p>-->
  <!--        <p class="details">{{"SIMPLE.ORDER.DETAILS" | translate}}</p>-->
  <!--      </p-card>-->
  <!--    </div>-->
  <!--    <div class="p-col-12 p-md-6">-->
  <!--&lt;!&ndash;      <p-card (click)="createDiagnostics()">&ndash;&gt;-->
  <!--&lt;!&ndash;        <img src="../../../../../../assets/icons/board.svg" alt="">&ndash;&gt;-->
  <!--&lt;!&ndash;        <p class="main-text">{{'WITH.DIAGNOSTICS' | translate}}</p>&ndash;&gt;-->
  <!--&lt;!&ndash;        <p class="details">{{"DIAGNOSTICS.ORDER.DETAILS" | translate}}</p>&ndash;&gt;-->
  <!--&lt;!&ndash;      </p-card>&ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="extra"></div>
  <div class="p-grid client-dialog-footer">
    <div class="p-col-12 p-md-6">
      <app-prolabo-icon-button
        (click)="previous()"
        [icon]="'left-arrow'"
        [name]="'PREVIOUS'"
        [position]="'left'"
        type="primary"></app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-6" *ngIf="isUser">
      <app-prolabo-icon-button
        (click)="createTemp()"
        [disabled]="laboratoryId === -1"
        [icon]="'arrow-right-white'"
        [name]="'NEXT'"
        [position]="'right-arrow'"
        type="primary">
      </app-prolabo-icon-button>
    </div>
    <div class="p-col-12 p-md-6" *ngIf="!isUser">
      <app-prolabo-icon-button
        (click)="createTemp2()"
        [disabled]="userId === -1"
        [icon]="'arrow-right-white'"
        [name]="'NEXT'"
        [position]="'right-arrow'"
        type="primary">
      </app-prolabo-icon-button>
    </div>
  </div>
</div>

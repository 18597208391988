<div class="orders-pagination-list">
  <ng-container *ngIf="items">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="list-labels">
          <div class="sublist-items">
            <ng-container *ngFor="let header of headers">
                            <span *ngIf="header.title !== 'DATE'">
                                <p class="sort-label {{header.sortLabel}}"
                                   (click)="header.sortLabel !== 'sample' ? sortByLabel(header.sortLabel) : null">
                                    {{ header.title | translate }}
                                  <span *ngIf="filter && header.sortLabel === 'latest_state'" class="filter-btn">
                                    <app-prolabo-icon-button
                                      [name]="functions.getFilterName(filter)"
                                      type="transparent"
                                      (click)="deleteFilter()"
                                      [icon]="'close'">
                                    </app-prolabo-icon-button>
                                  </span>

                                  <span
                                    *ngIf="header.sortLabel === 'sample'"
                                    pTooltip="{{ 'FILTER_SAMPLES' | translate }}"
                                    tooltipPosition="top"
                                    class="filter-icon {{sampleIsSelected ? 'filter-background' : ''}}">
                                    <img src="../../../../../assets/icons/filter.svg">
                                    <p-dropdown
                                      [options]="filterValues"
                                      [formControl]="selectedSample"
                                      optionValue="id"
                                      optionLabel="text"
                                      (onChange)="handleFilterDropdownChange($event)"
                                      class="dropdown-hidden"></p-dropdown>
                                  </span>

                                  <span
                                    *ngIf="!filter && header.sortLabel === 'latest_state'"
                                    pTooltip="{{ 'FILTER_STATE' | translate }}"
                                    tooltipPosition="top"
                                    class="filter-icon">
                                    <img
                                      src="{{stateIsSelected ?'../../../../../assets/icons/filter_blue.svg' : '../../../../../assets/icons/filter.svg'}}">
                                    <p-dropdown
                                      [options]="filterByState"
                                      [formControl]="selectedState"
                                      optionValue="id"
                                      optionLabel="text"
                                      (onChange)="handleFilterByStateDropdownChange($event)"
                                      class="dropdown-hidden">
                                    </p-dropdown>
                                  </span>

                                  <span
                                    *ngIf="header.sortLabel === 'city'"
                                    pTooltip="{{ 'FILTER_CITY' | translate }}"
                                    tooltipPosition="top"
                                    class="filter-icon {{cityIsSelected ? 'filter-background' : ''}}">
                                    <img src="../../../../../assets/icons/filter.svg">
                                    <p-dropdown
                                      [options]="filterByCity"
                                      [formControl]="selectedCity"
                                      optionValue="id"
                                      optionLabel="text"
                                      (onChange)="handleFilterByCityDropdownChange($event)"
                                      class="dropdown-hidden">
                                    </p-dropdown>
                                  </span>
                                  <span *ngIf="getAscValueForHeader(header.sortLabel)" class="icon">
                                    <img src="../../../../assets/icons/down-arrow.svg"/>
                                  </span>
                                  <span *ngIf="!getAscValueForHeader(header.sortLabel)" class="icon">
                                    <img src="../../../../assets/icons/up-arrow.svg"/>
                                  </span>
                                </p>
                            </span>
            </ng-container>
          </div>
          <p class="date sort-label created_at"
             (click)="sortByLabel(getLastHeader()['sortLabel'])">
            {{ getLastHeader()['title'] | translate }}
            <span *ngIf="getAscValueForHeader('created_at')" class="icon">
                            <img src="../../../../assets/icons/down-arrow.svg"/>
                        </span>
            <span *ngIf="!getAscValueForHeader('created_at')" class="icon">
                            <img src="../../../../assets/icons/up-arrow.svg"/>
                        </span>
          </p>
        </div>
        <ng-container *ngFor="let item of items">
          <div class="list-item">
            <div class="sublist-items">
              <p class="overflow {{ item.latest_state?.id != 6 ? 'cursor' : '' }}"
                 pTooltip="{{item.order_number}}"
                 tooltipPosition="top" (click)="navigate(item.id,item.type?.name, item.latest_state?.id)">
                {{ item.order_number }}
              </p>
              <p class="overflow light-text"
                 pTooltip="{{item.client}}"
                 tooltipPosition="top">
                {{ item.client }}
              </p>
              <p class="overflow"
                 pTooltip="{{item.site}}"
                 tooltipPosition="top">
                {{ item.site }}
              </p>
              <p class="progressbar" pTooltip="{{functions.getProgressBarTooltipValue(item.latest_state?.id)}}"
                 tooltipPosition="top">
                <app-prolabo-progress-bar
                  [value]="functions.getProgressBarValues(item.latest_state?.id)['value']"
                  [name]="functions.getProgressBarValues(item.latest_state?.id)['name']"
                  [step]="functions.getProgressBarValues(item.latest_state?.id)['step']"></app-prolabo-progress-bar>
              </p>
              <p class="mobile-progress">
                {{ functions.getProgressBarValues(item.latest_state?.id)['step'] }} / {{ totalSteps }}
              </p>
              <p class="overflow"
                 pTooltip="{{ functions.getItemValues(item.sample_counts) }}"
                 tooltipPosition="top">
                {{ functions.getItemValues(item.sample_counts) }}
              </p>
              <p class="overflow light-text"
                 pTooltip="{{item.type?.name | titlecase}}"
                 tooltipPosition="top">
                {{ item.type?.name | titlecase }}
              </p>
              <p class="overflow light-text"
                 pTooltip="{{item.company}}"
                 tooltipPosition="top">
                {{ item.company }}
              </p>
              <p class="overflow light-text"
                 pTooltip="{{item.laboratory | titlecase}}"
                 tooltipPosition="top">
                {{ item.laboratory | titlecase }}
              </p>
            </div>
            <p class="created_at">
              {{ item.created_at | toDateObj | date: "dd.MM.yyyy" }}
              <span class="light-text time">&nbsp; &nbsp; {{ item.created_at | toDateObj | date: "HH:mm" }}</span>
            </p>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <p class="total">
          {{ totalItems }} {{ 'SAMPLES' | translate }}:
          <ng-container *ngFor="let value of itemValues">
            <ng-container *ngIf="value && value.count > 0">
              <span class="light">{{ value.count }} {{ getTotalItemName(value.name) }} &nbsp;</span>
            </ng-container>
          </ng-container>
        </p>
      </div>
      <div class="p-col-12 p-md-6 inline">
        <p>{{ 'ROWS_PER_PAGE' | translate }}: </p>
        <p-dropdown
          [options]="[10, 20, 30]"
          class="row-dropdown"
          (onChange)="onLimitChange($event)"></p-dropdown>
        <p>&nbsp; {{ numberRows * page + 1 }} - {{ numberRows * page + numberRows }} of {{ total }}</p>
        <p-paginator
          class="paginator"
          [rows]="numberRows"
          [totalRecords]="total"
          [showJumpToPageDropdown]="false"
          [showPageLinks]="false"
          (onPageChange)="onPageChange($event)"></p-paginator>
      </div>
    </div>
  </ng-container>
</div>
